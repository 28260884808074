// header
.header {
	background-image: url(../images/hero.jpg);
	background-position: center;
	background-size: cover;
	background-color: #eee;
	height: 44vw;
	transition: all .4s;
	position: relative;
	margin-bottom: 10%;
	.logo {
		width: 9vw;
	}
}
.header-container {
	position: relative;
	height: 100%;
}
.header-menu {
	margin-right: 3rem;
	font-size: 1.5rem;
	.menu-item {
		margin: 0 1rem;
	}
}
.hero-text-wrap {
	position: absolute;
	right: 0;
	bottom: 0;
	margin-bottom: 3vw;
}
.hero-text {
	font-size: 1.5vw;
	.inner-wrap {
		display: block;
		margin: 2rem;
	}
	.inner {
		background-color: #fff;
		padding: 1rem;
		display: inline-block;
		letter-spacing: 10px;
		font-weight: normal;
	}
}
.creditcard {
	display: inline-flex;
	align-items: center;
	margin-top: 1rem;
	background-color: rgba(255, 255, 255, 0.8);
	padding: 1rem;
	img {
		width: 3rem;
		display: inline;
		margin-right: 1rem;
	}
}

@include mq(tab) {
	.header {
		height: 74vw;
	}
	.hero-text {
		font-size: 2.5vw;
	}
}

@include mq {
	.header {
		height: 90vh;
		.item-l {
			width: 20vw;
		}
	}
	.hero-text {
		font-size: 6vw;
		.inner-wrap {
			display: block;
			margin: 1rem;
		}
		.inner {
			background-color: #fff;
			padding: .75rem .5rem;
			display: inline-block;
			letter-spacing: 6px;
			font-weight: normal;
		}
	}
	.creditcard {
		display: none;
	}
	.header {
		background-image: url(../images/hero2.jpg);
	}
}
