.title {
	font-family: $mincho;
}
.title-main {
	font-size: 3rem;
	text-align: center;
	margin-bottom: 4rem;
	overflow: hidden;
	&::before {
		content: "";
		background: url(../images/icon/svg/title-icon.svg) center no-repeat;
		width: 50px;
		height: 50px;
		margin: 0 auto 2rem;
	}
}


@include mq {
	.title-main {
		font-size: 2rem;
		text-align: center;
		margin: 4rem auto 2rem;
		&::before {
			width: 32px;
			height: 32px;
			margin-bottom: 1rem;
		}
	}
}
