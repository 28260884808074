// https://fontawesome.com/v4.7.0/icons/
@charset "UTF-8";
@import '_var';

*,*::before,*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
*::before,*::after {
	display: block;
}
html {
	width: 100%;
	height: 100%;
	font-size: 16px;
	// background-color: $black-bg;
}
body {
	margin: 0;
	font-size: 16px;
	letter-spacing: 1px;
	color: black;
	font-family: $basefont;
	font-weight: 400;
	word-break: break-all;
	line-height: 1.6;
	-webkit-text-size-adjust: 100%;
	-webkit-font-smoothing: antialiased;
	background-image: url(../images/bg.jpg);
	background-position: center;
}
a {
	transition: all .2s;
	text-decoration: none;
	color: $black;
	cursor: pointer;
	display: block;
}
a,span {
	color: inherit;
}
ul,dl {
	list-style: none;
}
dt,dd,th,td,ol {
}
input {
	&:hover {
		cursor: pointer;
	}
}
input,textarea,select {
	appearance: none;
	line-height: 1.6;
	border: none;
}
input,textarea {
	display: inline-block;
	background: #fff;
	font-size: inherit;
	border-radius: 4px;
	width: 100%;
	&::placeholder {
		font-size: .9rem;
	}
	&:hover {
		cursor: pointer;
	}
}
button {
	border: 0;
	outline: none;
	appearance: none;
	cursor: pointer;
	background: none;
}
hr {
	border-color: $gray;
	border-width: 1px 0 0 0;
	margin: 1em auto;
}
img {
	display: block;
	max-width: 100%;
	height: auto;
}
iframe {
	display: block;
	width: 100%;
	background-color: $gray;
}
address {
	font-style: normal;
	margin-top: 2rem;
}
table {
	width: 100%;
}
strong {
	font-size: 1.25rem;
}
address {
	margin: 0;
}
rt {
	font-weight: normal;
	font-size: 1rem;
}

// inclulde 
@import '_mixins';
@import '_extends';
@import '_common';
@import "parts/atoms/_btn.scss";
@import "parts/atoms/_nav.scss";
@import "parts/atoms/font/_font.scss";
@import "parts/atoms/font/_title.scss";
@import "parts/molecules/_footer.scss";
@import "parts/molecules/_frame.scss";
@import "parts/molecules/_global-nav.scss";
@import "parts/molecules/_header.scss";
@import "parts/molecules/_interaction.scss";
@import "parts/molecules/_loading.scss";
@import "parts/molecules/_pagination.scss";
@import "parts/molecules/_popup.scss";
@import "parts/molecules/_tab.scss";
@import "parts/molecules/_table.scss";
@import '_top';

@include mq {
	.dn {
		display: block;
	}
	.tl-section {
		padding: 4rem 0;
	}
}
