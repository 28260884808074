.footer {
	background: #000;
	color: #fff;
}

.footer-cnt {
	padding: 8rem 0;
	max-width: 980px;
	margin: 0 auto;
	.bg {
		background-image: url(../images/footer-gaikan.png);
		background-size: cover;
		background-position: center;
	}
	.cnt {
		.logo {
			margin-bottom: 2rem;
		}
		.map-wrap {
			align-items: center;
		}
		.info {
			margin-bottom: 1rem;
		}
		.map {
			border:1px solid #fff;
			line-height: 1;
			padding: .5rem;
			margin-left: 1rem;
			font-size: 12px;
		}
	}
}

.address {
	margin-bottom: 1rem;
}

.business-hours {
	margin-bottom: 1rem;
	.creditcard {
		background: #fff;
		color: #000;
	}
}

.reserve {
	background-image: url(../images/footer-reserve.jpg);
	background-size: cover;
	background-position: center;
	padding: 6rem 0;
	text-align: center;
	color: #fff;
	font-size: 2rem;
	a {
		padding: 2rem 5rem;
		font-size: 6rem;
		color: #fff;
		background-color: red;
		border-radius: 87px;
		display: inline-block;
		line-height: 1;
		margin-top: 3rem;
	}
}



@include mq() {
	.reserve {
		font-size: 6.25vw;
		padding: 2rem 0;
		a {
			padding: 2rem 1rem;
			font-size: 12.25vw;
			margin-top: 3rem;
			width: 100%;
			margin-top: 1rem;
			padding: 2rem 0;
		}
	}
	.footer-address {
		font-size: .8rem;
	}
	.footer-cnt {
		padding: 2rem 1rem;
		.cnt {
			.info {
				margin-bottom: 1rem;
				display: block;
			}
			.map {
				display: inline-block;
				margin-top: .5rem;
			}
			.map-wrap {
				display: block;
			}
		}
	}
	.business-hours {
		.creditcard {
			position: static;
			display: flex;
			font-size: .8rem;
		}
	}
}
