@charset "UTF-8";
*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

*::before, *::after {
  display: block; }

html {
  width: 100%;
  height: 100%;
  font-size: 16px; }

body {
  margin: 0;
  font-size: 16px;
  letter-spacing: 1px;
  color: black;
  font-family: futura-pt, ryo-gothic-plusn, "Yu Gothic", YuGothic, -apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Helvetica, sans-serif;
  font-weight: 400;
  word-break: break-all;
  line-height: 1.6;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  background-image: url(../images/bg.jpg);
  background-position: center; }

a {
  transition: all .2s;
  text-decoration: none;
  color: #464646;
  cursor: pointer;
  display: block; }

a, span {
  color: inherit; }

ul, dl {
  list-style: none; }

input:hover {
  cursor: pointer; }

input, textarea, select {
  appearance: none;
  line-height: 1.6;
  border: none; }

input, textarea {
  display: inline-block;
  background: #fff;
  font-size: inherit;
  border-radius: 4px;
  width: 100%; }
  input::placeholder, textarea::placeholder {
    font-size: .9rem; }
  input:hover, textarea:hover {
    cursor: pointer; }

button {
  border: 0;
  outline: none;
  appearance: none;
  cursor: pointer;
  background: none; }

hr {
  border-color: #F0F0F0;
  border-width: 1px 0 0 0;
  margin: 1em auto; }

img {
  display: block;
  max-width: 100%;
  height: auto; }

iframe {
  display: block;
  width: 100%;
  background-color: #F0F0F0; }

address {
  font-style: normal;
  margin-top: 2rem; }

table {
  width: 100%; }

strong {
  font-size: 1.25rem; }

address {
  margin: 0; }

rt {
  font-weight: normal;
  font-size: 1rem; }

.centering, .centering_fix, .theme-hr::after, .comp-thumbbox::after, .loading .cnt, .menu-title span, .menu-title::after {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center; }
  .centeringY {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .centeringX {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); }
  .centering_fix {
    position: fixed; }

.centering_fix {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.relative {
  position: relative; }

.inline {
  display: inline; }

.ib {
  display: inline-block; }

.fbc-wrap {
  display: flex;
  justify-content: center; }

.modaal-noscroll {
  overflow: auto !important; }

.dn {
  display: none; }

.pink {
  color: #FF99CC; }

.bg-red {
  background-color: #FF223C;
  color: #fff; }

.bg-green {
  background-color: #4FC994;
  color: #fff; }

.theme-hr {
  width: 100%;
  height: 2px;
  background: #EFEFEF;
  position: relative;
  z-index: 0;
  margin: 4rem auto; }
  .theme-hr::after {
    content: "";
    width: 16px;
    height: 16px;
    background: #000;
    border: 4px solid #fff;
    border-radius: 50%; }

.table {
  border-collapse: collapse; }
  .table th, .table td {
    padding: 1rem;
    border: 1px solid white; }
  .table th {
    width: 30%; }

.mb-visible {
  display: none; }

.img-wrap {
  margin: 2rem auto; }

.text-box {
  margin: 4rem auto; }

.comp-thumbbox {
  position: relative;
  z-index: 0;
  overflow: hidden; }
  .comp-thumbbox::before, .comp-thumbbox::after {
    transition: all .6s;
    pointer-events: none; }
  .comp-thumbbox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.75); }
  .comp-thumbbox::after {
    content: "MORE →";
    z-index: 3;
    top: 70%;
    opacity: 0;
    padding: .5rem;
    border: 1px solid;
    letter-spacing: 1px;
    line-height: 1; }
  .comp-thumbbox:hover::before {
    height: 100%; }
  .comp-thumbbox:hover::after {
    opacity: 1;
    top: 50%; }

.figure img {
  width: 100%;
  margin: 1rem auto; }

@media screen and (max-width: 767px) {
  .mb-block {
    display: block; }
  .mb-visible {
    display: block; }
  .mb-hidden {
    display: none; } }

@media screen and (max-width: 600px) {
  .theme-hr {
    margin: 4rem auto; } }

.btn-tel {
  background-color: red;
  display: inline-block;
  padding: .74em 1em;
  line-height: 1;
  border-radius: 4px;
  font-size: 2vw; }

.btn:hover {
  transform: scale(1.1); }

.underline {
  text-decoration: underline; }

.mincho {
  font-family: ryo-text-plusn, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; }

.small {
  font-size: .75rem; }

.medium {
  font-size: 1.5rem; }

.large {
  font-size: 2rem; }

.bold {
  font-weight: bold; }

.green {
  color: #4FC994; }

.red {
  color: #FF223C; }

.attention {
  color: #F92828; }

.center {
  text-align: center; }

.right {
  text-align: right; }

.vertical {
  writing-mode: vertical-rl;
  white-space: nowrap;
  line-break: strict;
  text-align: justify; }

.txt-tranform-capitalize {
  text-transform: capitalize; }

.bold {
  font-weight: bold; }

.link:hover {
  color: #4ac4ff; }

.link-inline {
  color: #4ac4ff;
  text-decoration: underline;
  display: inline;
  margin-right: .25rem;
  margin-left: .25rem; }

.link2:hover {
  text-decoration: underline; }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .small {
    font-size: 1vw; } }

.title {
  font-family: ryo-text-plusn, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif; }

.title-main {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 4rem;
  overflow: hidden; }
  .title-main::before {
    content: "";
    background: url(../images/icon/svg/title-icon.svg) center no-repeat;
    width: 50px;
    height: 50px;
    margin: 0 auto 2rem; }

@media screen and (max-width: 767px) {
  .title-main {
    font-size: 2rem;
    text-align: center;
    margin: 4rem auto 2rem; }
    .title-main::before {
      width: 32px;
      height: 32px;
      margin-bottom: 1rem; } }

.footer {
  background: #000;
  color: #fff; }

.footer-cnt {
  padding: 8rem 0;
  max-width: 980px;
  margin: 0 auto; }
  .footer-cnt .bg {
    background-image: url(../images/footer-gaikan.png);
    background-size: cover;
    background-position: center; }
  .footer-cnt .cnt .logo {
    margin-bottom: 2rem; }
  .footer-cnt .cnt .map-wrap {
    align-items: center; }
  .footer-cnt .cnt .info {
    margin-bottom: 1rem; }
  .footer-cnt .cnt .map {
    border: 1px solid #fff;
    line-height: 1;
    padding: .5rem;
    margin-left: 1rem;
    font-size: 12px; }

.address {
  margin-bottom: 1rem; }

.business-hours {
  margin-bottom: 1rem; }
  .business-hours .creditcard {
    background: #fff;
    color: #000; }

.reserve {
  background-image: url(../images/footer-reserve.jpg);
  background-size: cover;
  background-position: center;
  padding: 6rem 0;
  text-align: center;
  color: #fff;
  font-size: 2rem; }
  .reserve a {
    padding: 2rem 5rem;
    font-size: 6rem;
    color: #fff;
    background-color: red;
    border-radius: 87px;
    display: inline-block;
    line-height: 1;
    margin-top: 3rem; }

@media screen and (max-width: 767px) {
  .reserve {
    font-size: 6.25vw;
    padding: 2rem 0; }
    .reserve a {
      padding: 2rem 1rem;
      font-size: 12.25vw;
      margin-top: 3rem;
      width: 100%;
      margin-top: 1rem;
      padding: 2rem 0; }
  .footer-address {
    font-size: .8rem; }
  .footer-cnt {
    padding: 2rem 1rem; }
    .footer-cnt .cnt .info {
      margin-bottom: 1rem;
      display: block; }
    .footer-cnt .cnt .map {
      display: inline-block;
      margin-top: .5rem; }
    .footer-cnt .cnt .map-wrap {
      display: block; }
  .business-hours .creditcard {
    position: static;
    display: flex;
    font-size: .8rem; } }

.flex, .flex-50, .flex-70-30, .flex-40-60 {
  display: flex; }

.flex-wrap, .flex-wrap-32, .flex-wrap-31, .flex-wrap-48 {
  display: flex;
  flex-wrap: wrap; }

.ai-center {
  align-items: center; }

.flex-between, .flex-48, .flex-32, .flex-31, .flex-20-75, .flex-25-70, .flex-30-70 {
  display: flex;
  justify-content: space-between; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }

.flex-vertical {
  display: flex;
  flex-direction: column; }

.flex-column-between {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .flex-column-between .item2 {
    text-align: end; }

.flex-50 > .item {
  flex-basis: 50%; }

.flex-48 > .item {
  flex-basis: 48%; }

.flex-32 {
  flex-wrap: wrap; }
  .flex-32 > .item {
    flex-basis: 32%; }

.flex-31 {
  flex-wrap: wrap; }
  .flex-31 > .item {
    flex-basis: 31%; }

.flex-70-30 > .item-l {
  flex-basis: 70%; }

.flex-70-30 > .item-r {
  flex-basis: 30%; }

.flex-40-60 > .item-l {
  flex-basis: 40%; }

.flex-40-60 > .item-r {
  flex-basis: 60%; }

.flex-20-75 > .item-l {
  flex-basis: 20%; }

.flex-20-75 > .item-r {
  flex-basis: 75%; }

.flex-25-70 > .item-l {
  flex-basis: 25%; }

.flex-25-70 > .item-r {
  flex-basis: 70%; }

.flex-30-70 > .item-l {
  flex-basis: 30%; }

.flex-30-70 > .item-r {
  flex-basis: 70%; }

.flex-wrap-32 .item {
  flex-basis: 32%;
  margin-right: 2%;
  margin-bottom: 2%; }
  .flex-wrap-32 .item:nth-child(3n) {
    margin-right: 0; }

.flex-wrap-31 .item {
  flex-basis: 31%;
  margin-right: 3.5%;
  margin-bottom: 3.5%; }
  .flex-wrap-31 .item:nth-child(3n) {
    margin-right: 0; }

.flex-wrap-48 .item {
  flex-basis: 48%;
  margin-right: 4%;
  margin-bottom: 4%; }
  .flex-wrap-48 .item:nth-child(2n) {
    margin-right: 0; }

.flex-wrap-48.v2 .item:first-child {
  flex-basis: 100%; }

.flex-wrap-48.v2 .item:nth-child(2n) {
  margin-right: 4%; }

.flex-wrap-48.v2 .item:nth-child(odd) {
  margin-right: 0; }

.display-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1px;
  border-bottom: 1px solid #eaeaea; }
  .display-table .cell {
    display: table-cell;
    padding: 1rem; }
    .display-table .cell:first-child {
      width: 8rem; }

.between {
  display: flex;
  justify-content: space-between; }

.block {
  display: block; }

.wrapper, .wrapper0 {
  width: 1200px;
  max-width: 90%;
  margin: 0 auto; }

.section {
  margin: 7rem auto; }

.section2 {
  margin: 2rem auto; }

.wrapper0 {
  padding: 0 1rem; }

.figure figcaption {
  padding: 1rem;
  line-height: 2; }

.scroll-wrap {
  overflow: auto; }

@media screen and (min-width: 1600px) {
  .wrapper, .wrapper0 {
    width: 1300px;
    max-width: 90%; } }

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .wrapper, .wrapper0 {
    width: 1080px;
    max-width: 90%; } }

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .wrapper, .wrapper0 {
    width: 960px;
    max-width: 90%; } }

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .wrapper, .wrapper0 {
    width: calc(1000px + 2rem);
    max-width: 80%; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .wrapper, .wrapper0 {
    width: calc(1000px + 2rem);
    max-width: 90%; } }

@media screen and (max-width: 600px) {
  .flex-50, .flex-wrap-48, .flex-48, .flex-wrap-32 {
    display: block; }
    .flex-50 .item, .flex-wrap-48 .item, .flex-48 .item, .flex-wrap-32 .item {
      margin-right: 0; }
  .section {
    margin: 3rem auto; }
  .wrapper, .wrapper0 {
    max-width: calc(100% - 2rem);
    padding: 0; } }

.header {
  background-image: url(../images/hero.jpg);
  background-position: center;
  background-size: cover;
  background-color: #eee;
  height: 44vw;
  transition: all .4s;
  position: relative;
  margin-bottom: 10%; }
  .header .logo {
    width: 9vw; }

.header-container {
  position: relative;
  height: 100%; }

.header-menu {
  margin-right: 3rem;
  font-size: 1.5rem; }
  .header-menu .menu-item {
    margin: 0 1rem; }

.hero-text-wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 3vw; }

.hero-text {
  font-size: 1.5vw; }
  .hero-text .inner-wrap {
    display: block;
    margin: 2rem; }
  .hero-text .inner {
    background-color: #fff;
    padding: 1rem;
    display: inline-block;
    letter-spacing: 10px;
    font-weight: normal; }

.creditcard {
  display: inline-flex;
  align-items: center;
  margin-top: 1rem;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem; }
  .creditcard img {
    width: 3rem;
    display: inline;
    margin-right: 1rem; }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .header {
    height: 74vw; }
  .hero-text {
    font-size: 2.5vw; } }

@media screen and (max-width: 767px) {
  .header {
    height: 90vh; }
    .header .item-l {
      width: 20vw; }
  .hero-text {
    font-size: 6vw; }
    .hero-text .inner-wrap {
      display: block;
      margin: 1rem; }
    .hero-text .inner {
      background-color: #fff;
      padding: .75rem .5rem;
      display: inline-block;
      letter-spacing: 6px;
      font-weight: normal; }
  .creditcard {
    display: none; }
  .header {
    background-image: url(../images/hero2.jpg); } }

.fade-in, .fade-in-right, .text-fade-in {
  opacity: 0;
  position: relative;
  z-index: 1;
  transform: translateY(2rem);
  transition: all 1s; }
  .fade-in.on, .on.fade-in-right, .on.text-fade-in {
    opacity: 1;
    transform: translateY(0); }

.fade-in-right {
  transform: translateX(2rem); }
  .fade-in-right.on {
    transform: translateX(0); }

.text-fade-in {
  display: inline-block;
  letter-spacing: 14px; }
  .text-fade-in.on {
    letter-spacing: 2px; }

.slide-in, .text-slide-in {
  opacity: 0;
  position: relative;
  transform: translateX(-2rem);
  transition: all 1s; }
  .slide-in.on, .on.text-slide-in {
    opacity: 1;
    transform: translateX(0); }

.slide-in-wrapper .item:nth-child(1) .text-slide-in {
  transition-delay: 0.2s; }

.slide-in-wrapper .item:nth-child(2) .text-slide-in {
  transition-delay: 0.4s; }

.slide-in-wrapper .item:nth-child(3) .text-slide-in {
  transition-delay: 0.6s; }

.slide-in-wrapper .item:nth-child(4) .text-slide-in {
  transition-delay: 0.8s; }

.slide-in-wrapper .item:nth-child(5) .text-slide-in {
  transition-delay: 1s; }

.slide-in-wrapper .item:nth-child(6) .text-slide-in {
  transition-delay: 1.2s; }

.slide-in-wrapper .item:nth-child(7) .text-slide-in {
  transition-delay: 1.4s; }

.slide-in-wrapper .item:nth-child(8) .text-slide-in {
  transition-delay: 1.6s; }

.slide-in-wrapper .item:nth-child(9) .text-slide-in {
  transition-delay: 1.8s; }

.slide-in-wrapper .item:nth-child(10) .text-slide-in {
  transition-delay: 2s; }

.slide-in-wrapper .item:nth-child(11) .text-slide-in {
  transition-delay: 2.2s; }

.slide-in-wrapper .item:nth-child(12) .text-slide-in {
  transition-delay: 2.4s; }

.slide-in-wrapper .item:nth-child(13) .text-slide-in {
  transition-delay: 2.6s; }

.slide-in-wrapper .item:nth-child(14) .text-slide-in {
  transition-delay: 2.8s; }

.slide-in-wrapper .item:nth-child(15) .text-slide-in {
  transition-delay: 3s; }

.slide-in-wrapper .item:nth-child(16) .text-slide-in {
  transition-delay: 3.2s; }

.slide-in-wrapper .item:nth-child(17) .text-slide-in {
  transition-delay: 3.4s; }

.slide-in-wrapper .item:nth-child(18) .text-slide-in {
  transition-delay: 3.6s; }

.slide-in-wrapper .item:nth-child(19) .text-slide-in {
  transition-delay: 3.8s; }

.slide-in-wrapper .item:nth-child(20) .text-slide-in {
  transition-delay: 4s; }

.slide-in-wrapper .item:nth-child(21) .text-slide-in {
  transition-delay: 4.2s; }

.slide-in-wrapper .item:nth-child(22) .text-slide-in {
  transition-delay: 4.4s; }

.slide-in-wrapper .item:nth-child(23) .text-slide-in {
  transition-delay: 4.6s; }

.slide-in-wrapper .item:nth-child(24) .text-slide-in {
  transition-delay: 4.8s; }

.slide-in-wrapper .item:nth-child(25) .text-slide-in {
  transition-delay: 5s; }

.slide-in-wrapper .item:nth-child(26) .text-slide-in {
  transition-delay: 5.2s; }

.slide-in-wrapper .item:nth-child(27) .text-slide-in {
  transition-delay: 5.4s; }

.slide-in-wrapper .item:nth-child(28) .text-slide-in {
  transition-delay: 5.6s; }

.slide-in-wrapper .item:nth-child(29) .text-slide-in {
  transition-delay: 5.8s; }

.slide-in-wrapper .item:nth-child(30) .text-slide-in {
  transition-delay: 6s; }

.slide-in-wrapper .item:nth-child(31) .text-slide-in {
  transition-delay: 6.2s; }

.slide-in-wrapper .item:nth-child(32) .text-slide-in {
  transition-delay: 6.4s; }

.slide-in-wrapper .item:nth-child(33) .text-slide-in {
  transition-delay: 6.6s; }

.slide-in-wrapper .item:nth-child(34) .text-slide-in {
  transition-delay: 6.8s; }

.slide-in-wrapper .item:nth-child(35) .text-slide-in {
  transition-delay: 7s; }

.slide-in-wrapper .item:nth-child(36) .text-slide-in {
  transition-delay: 7.2s; }

.slide-in-wrapper .item:nth-child(37) .text-slide-in {
  transition-delay: 7.4s; }

.slide-in-wrapper .item:nth-child(38) .text-slide-in {
  transition-delay: 7.6s; }

.slide-in-wrapper .item:nth-child(39) .text-slide-in {
  transition-delay: 7.8s; }

.slide-in-wrapper .item:nth-child(40) .text-slide-in {
  transition-delay: 8s; }

.slide-in-wrapper .item:nth-child(41) .text-slide-in {
  transition-delay: 8.2s; }

.slide-in-wrapper .item:nth-child(42) .text-slide-in {
  transition-delay: 8.4s; }

.slide-in-wrapper .item:nth-child(43) .text-slide-in {
  transition-delay: 8.6s; }

.slide-in-wrapper .item:nth-child(44) .text-slide-in {
  transition-delay: 8.8s; }

.slide-in-wrapper .item:nth-child(45) .text-slide-in {
  transition-delay: 9s; }

.slide-in-wrapper .item:nth-child(46) .text-slide-in {
  transition-delay: 9.2s; }

.slide-in-wrapper .item:nth-child(47) .text-slide-in {
  transition-delay: 9.4s; }

.slide-in-wrapper .item:nth-child(48) .text-slide-in {
  transition-delay: 9.6s; }

.slide-in-wrapper .item:nth-child(49) .text-slide-in {
  transition-delay: 9.8s; }

.slide-in-wrapper .item:nth-child(50) .text-slide-in {
  transition-delay: 10s; }

.slide-in-wrapper .item:nth-child(51) .text-slide-in {
  transition-delay: 10.2s; }

.slide-in-wrapper .item:nth-child(52) .text-slide-in {
  transition-delay: 10.4s; }

.slide-in-wrapper .item:nth-child(53) .text-slide-in {
  transition-delay: 10.6s; }

.slide-in-wrapper .item:nth-child(54) .text-slide-in {
  transition-delay: 10.8s; }

.slide-in-wrapper .item:nth-child(55) .text-slide-in {
  transition-delay: 11s; }

.slide-in-wrapper .item:nth-child(56) .text-slide-in {
  transition-delay: 11.2s; }

.slide-in-wrapper .item:nth-child(57) .text-slide-in {
  transition-delay: 11.4s; }

.slide-in-wrapper .item:nth-child(58) .text-slide-in {
  transition-delay: 11.6s; }

.slide-in-wrapper .item:nth-child(59) .text-slide-in {
  transition-delay: 11.8s; }

.slide-in-wrapper .item:nth-child(60) .text-slide-in {
  transition-delay: 12s; }

.slide-in-wrapper .item:nth-child(61) .text-slide-in {
  transition-delay: 12.2s; }

.slide-in-wrapper .item:nth-child(62) .text-slide-in {
  transition-delay: 12.4s; }

.slide-in-wrapper .item:nth-child(63) .text-slide-in {
  transition-delay: 12.6s; }

.slide-in-wrapper .item:nth-child(64) .text-slide-in {
  transition-delay: 12.8s; }

.slide-in-wrapper .item:nth-child(65) .text-slide-in {
  transition-delay: 13s; }

.slide-in-wrapper .item:nth-child(66) .text-slide-in {
  transition-delay: 13.2s; }

.slide-in-wrapper .item:nth-child(67) .text-slide-in {
  transition-delay: 13.4s; }

.slide-in-wrapper .item:nth-child(68) .text-slide-in {
  transition-delay: 13.6s; }

.slide-in-wrapper .item:nth-child(69) .text-slide-in {
  transition-delay: 13.8s; }

.slide-in-wrapper .item:nth-child(70) .text-slide-in {
  transition-delay: 14s; }

.slide-in-wrapper .item:nth-child(71) .text-slide-in {
  transition-delay: 14.2s; }

.slide-in-wrapper .item:nth-child(72) .text-slide-in {
  transition-delay: 14.4s; }

.slide-in-wrapper .item:nth-child(73) .text-slide-in {
  transition-delay: 14.6s; }

.slide-in-wrapper .item:nth-child(74) .text-slide-in {
  transition-delay: 14.8s; }

.slide-in-wrapper .item:nth-child(75) .text-slide-in {
  transition-delay: 15s; }

.slide-in-wrapper .item:nth-child(76) .text-slide-in {
  transition-delay: 15.2s; }

.slide-in-wrapper .item:nth-child(77) .text-slide-in {
  transition-delay: 15.4s; }

.slide-in-wrapper .item:nth-child(78) .text-slide-in {
  transition-delay: 15.6s; }

.slide-in-wrapper .item:nth-child(79) .text-slide-in {
  transition-delay: 15.8s; }

.slide-in-wrapper .item:nth-child(80) .text-slide-in {
  transition-delay: 16s; }

.slide-in-wrapper .item:nth-child(81) .text-slide-in {
  transition-delay: 16.2s; }

.slide-in-wrapper .item:nth-child(82) .text-slide-in {
  transition-delay: 16.4s; }

.slide-in-wrapper .item:nth-child(83) .text-slide-in {
  transition-delay: 16.6s; }

.slide-in-wrapper .item:nth-child(84) .text-slide-in {
  transition-delay: 16.8s; }

.slide-in-wrapper .item:nth-child(85) .text-slide-in {
  transition-delay: 17s; }

.slide-in-wrapper .item:nth-child(86) .text-slide-in {
  transition-delay: 17.2s; }

.slide-in-wrapper .item:nth-child(87) .text-slide-in {
  transition-delay: 17.4s; }

.slide-in-wrapper .item:nth-child(88) .text-slide-in {
  transition-delay: 17.6s; }

.slide-in-wrapper .item:nth-child(89) .text-slide-in {
  transition-delay: 17.8s; }

.slide-in-wrapper .item:nth-child(90) .text-slide-in {
  transition-delay: 18s; }

.slide-in-wrapper .item:nth-child(91) .text-slide-in {
  transition-delay: 18.2s; }

.slide-in-wrapper .item:nth-child(92) .text-slide-in {
  transition-delay: 18.4s; }

.slide-in-wrapper .item:nth-child(93) .text-slide-in {
  transition-delay: 18.6s; }

.slide-in-wrapper .item:nth-child(94) .text-slide-in {
  transition-delay: 18.8s; }

.slide-in-wrapper .item:nth-child(95) .text-slide-in {
  transition-delay: 19s; }

.slide-in-wrapper .item:nth-child(96) .text-slide-in {
  transition-delay: 19.2s; }

.slide-in-wrapper .item:nth-child(97) .text-slide-in {
  transition-delay: 19.4s; }

.slide-in-wrapper .item:nth-child(98) .text-slide-in {
  transition-delay: 19.6s; }

.slide-in-wrapper .item:nth-child(99) .text-slide-in {
  transition-delay: 19.8s; }

.slide-in-wrapper .item:nth-child(100) .text-slide-in {
  transition-delay: 20s; }

@media screen and (max-width: 767px) {
  .text-fade-in {
    letter-spacing: 0;
    display: block; }
    .text-fade-in.on {
      letter-spacing: 0; } }

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;
  background-image: url(../images/loading-bg.jpg);
  background-position: center;
  background-size: cover; }
  .loading.open {
    top: -100%; }
  .loading .cnt {
    width: 25rem;
    max-width: 70%; }

.loading-logo img {
  margin: 0 auto; }

#loadingContainer {
  margin: 20px auto;
  width: 92%;
  height: 2px;
  position: relative; }

.progressbar-text {
  text-align: center;
  width: 100%;
  margin-top: 1.5rem !important; }

.curtain {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: all 1s 1s;
  z-index: 9998; }
  .curtain.open {
    top: -100%; }

@media screen and (max-width: 767px) {
  .pace {
    display: none; } }

.modaal-overlay {
  background-color: rgba(35, 37, 56, 0.95) !important;
  opacity: 1 !important; }

.modaal-container {
  width: 1200px !important;
  max-width: 90% !important;
  color: #fff !important;
  background: none !important;
  box-shadow: none !important; }

#highlightModal, #artistModal {
  display: none; }

.modaal-content-container {
  padding: 0 !important; }

.modal-sns {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 1rem; }
  .modal-sns .item {
    margin-right: 1rem; }
    .modal-sns .item:last-child {
      margin: 0; }

.modal-content p {
  margin: 2rem auto; }

.modal-content img {
  width: 100%; }

.modal-content .cnt {
  margin: 4rem; }
  .modal-content .cnt .title {
    font-size: 4rem;
    margin-bottom: 1rem;
    margin-top: -3rem; }

.modal-content .vertical {
  white-space: normal;
  height: 28rem;
  text-align: left;
  display: inline-block;
  margin-bottom: 4rem; }
  .modal-content .vertical:last-child {
    margin-bottom: 0; }
  .modal-content .vertical p {
    margin: 0 1rem;
    font-size: 1.25rem;
    line-height: 2;
    letter-spacing: 1px; }
  .modal-content .vertical .title {
    font-size: 3rem;
    margin-left: 5rem;
    letter-spacing: 6px;
    padding-left: 1rem;
    border-left: 1px solid; }
  .modal-content .vertical .stxt {
    margin-left: 0; }

.modal-content .vertical-box {
  text-align: center;
  margin: 6rem auto; }

@media screen and (max-width: 767px) {
  .modaal-container {
    width: 100% !important; }
  .modal-content .cnt {
    margin: 0; }
    .modal-content .cnt .title {
      font-size: 3rem;
      margin-top: -2rem;
      line-height: 1.4; }
  .modal-content .vertical-box {
    margin: 2rem auto; }
  .modal-content .vertical {
    writing-mode: initial; }
    .modal-content .vertical p {
      margin: 1rem auto;
      font-size: 1rem; }
    .modal-content .vertical .title {
      font-size: 3rem;
      margin: 0rem auto 2rem;
      letter-spacing: 6px;
      padding-left: 0;
      border-left: none; }
    .modal-content .vertical .stxt {
      margin: 0; } }

.tab {
  display: flex;
  margin: 0 auto !important; }
  .tab .item {
    cursor: pointer;
    padding: .5rem 1rem; }
    .tab .item.select {
      background-color: #F0F0F0; }
    .tab .item .title {
      margin: 0;
      font-size: .9rem; }

.tab-box {
  background-color: #F0F0F0;
  padding: 2rem; }
  .tab-box .item {
    display: none; }
    .tab-box .item.select {
      display: block; }
    .tab-box .item .title {
      color: #464646;
      font-weight: normal;
      margin-bottom: 1.5rem; }
    .tab-box .item .text {
      margin: 0; }
    .tab-box .item .content {
      margin-bottom: 2.5rem; }

.tab-cnt-wrapper {
  margin: 3rem auto; }

.tab-wrap {
  overflow: auto; }

@media screen and (max-width: 767px) {
  .tab {
    width: 41rem; } }

.tablepress td {
  padding: 1rem !important;
  border: 1px solid #fff !important; }

.tablepress iframe {
  margin-top: 1rem; }

.tablepress img {
  max-width: 100% !important; }

.tablepress .column-1 {
  width: 20%;
  text-align: center; }

@media screen and (max-width: 767px) {
  .tablepress .column-1 {
    width: 100%;
    text-align: center;
    display: block;
    background-color: #ffffff;
    color: black; }
  .tablepress .column-2 {
    display: block;
    width: 100%; } }

.top-title {
  font-size: 3vw;
  font-weight: normal;
  line-height: 1.4;
  margin-bottom: 3rem;
  text-align: center; }

.about-cnt > .item-left {
  background-image: url(../images/about.jpg);
  background-size: cover;
  background-position: center;
  margin-right: 3rem; }

.about-cnt > .item-right .title {
  font-size: 4.4vw;
  font-weight: normal;
  line-height: 1.4;
  margin-bottom: 0rem; }

.about-cnt > .item-right .text {
  margin: 2rem auto;
  line-height: 1.6vw; }

.about-sozai-cnt {
  text-align: center; }
  .about-sozai-cnt .text {
    margin: 2rem auto;
    line-height: 1.6vw; }

.sozai-detail img {
  width: 100%; }

.sozai-detail.last {
  justify-content: space-between;
  flex-basis: 48%; }

.menu-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem; }
  .menu-list dd {
    flex-shrink: 0;
    margin-left: 1.5rem; }

#menu {
  background-color: rgba(255, 255, 255, 0.73);
  padding-bottom: 1rem; }

.menu-title {
  background-image: url(../images/menu.jpg);
  background-size: cover;
  background-position: center;
  padding: 14rem 0;
  position: relative; }
  .menu-title span {
    display: block;
    color: #fff;
    z-index: 1;
    font-size: 5.5vw; }
  .menu-title::after {
    content: "";
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25); }

.menu-wrap {
  margin: 6rem auto; }
  .menu-wrap .title {
    font-size: 3rem;
    text-align: center;
    font-weight: normal;
    margin-bottom: 2rem; }
    .menu-wrap .title span {
      border-bottom: 1px solid;
      padding-bottom: .5rem; }

.subtitle {
  font-family: serif;
  font-size: 1.75rem;
  font-weight: normal;
  margin-bottom: 1rem; }

.enkai ul {
  margin-bottom: 2rem; }

.enkai .title {
  font-size: 2rem; }

.enkai-text {
  text-align: center;
  margin-bottom: 3rem; }

.enkai-img {
  margin: 4rem auto; }

@media screen and (min-width: 1600px) {
  .about-cnt > .item-right .title {
    font-size: 5.5rem; }
  .about-cnt > .item-right .text {
    line-height: 1.6; }
  .top-title {
    font-size: 4rem; }
  .about-sozai-cnt .text {
    line-height: 1.6; } }

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .about-txt p {
    font-size: 1.1rem; }
  .about-txt .title {
    font-size: 2rem; } }

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .about-cnt > .item-right .title {
    font-size: 6.4vw; }
  .about-cnt > .item-right .text {
    line-height: 1.5; }
  .top-title {
    font-size: 5vw; }
  .about-sozai-cnt .text {
    line-height: 1.5; }
  .menu-title {
    padding: 23vw 0; }
    .menu-title span {
      font-size: 11.5vw; }
  .enkai {
    display: block;
    text-align: center; }
    .enkai .bg {
      margin-bottom: 4rem; }
  .reserve a {
    font-size: 7vw; } }

@media screen and (max-width: 767px) {
  .about-cnt > .item-right .title {
    font-size: 14.4vw;
    font-weight: normal;
    line-height: 1.3;
    margin: 2rem auto; }
  .about-cnt > .item-right .text {
    line-height: 1.6; }
  .about-cnt > .item-left {
    height: 13rem;
    margin: 0; }
  .top-title {
    font-size: 6.7vw; }
  .about-sozai-cnt {
    text-align: left; }
    .about-sozai-cnt .text {
      line-height: 1.6; }
  .sozai-wrap {
    display: block; }
  .menu-title {
    padding: 7rem 0; }
    .menu-title span {
      font-size: 16.5vw;
      width: 90%; }
  .menu-wrap {
    margin: 0rem auto; }
  .menu-wrap-inner {
    display: block; }
    .menu-wrap-inner .item {
      margin: 0 0 2rem; }
  .enkai-img {
    margin: 2rem auto; }
  .enkai .title {
    margin: 2rem auto; } }

@media screen and (max-width: 767px) {
  .dn {
    display: block; }
  .tl-section {
    padding: 4rem 0; } }
