.modaal-overlay {
	background-color: rgba(35, 37, 56, 0.95) !important;
	opacity: 1 !important;
}
// .close-animatedModal {
// 	font-size: 3rem;
// 	text-align: center;
// 	margin: 2rem;
// 	position: fixed;
// 	top: 0;
// 	right: 0;
// 	line-height: 1;
// 	cursor: pointer;
// }
.modaal-container {
	width: 1200px !important;
	max-width: 90% !important;
	color: #fff !important;
	background: none !important;
	box-shadow: none !important;
}

#highlightModal,#artistModal {
	display: none;
}
.modaal-content-container {
	padding: 0 !important;
}

.modal-sns {
	display: flex;
	align-items: center;
	font-size: 1.5rem;
	margin-bottom: 1rem;
	.item {
		margin-right: 1rem;
		&:last-child {
			margin: 0;
		}
	}
}
.modal-content {
	p {
		margin: 2rem auto;
	}
	img {
		width: 100%;
	}
	.cnt {
		margin: 4rem;
		.title {
			font-size: 4rem;
			margin-bottom: 1rem;
			margin-top: -3rem;
		}
	}
	.vertical {
		white-space: normal;
		height: 28rem;
		text-align: left;
		display: inline-block;
		margin-bottom: 4rem;
		&:last-child {
			margin-bottom: 0;
		}
		p {
			margin: 0 1rem;
			font-size: 1.25rem;
			line-height: 2;
			letter-spacing: 1px;
		}
		.title {
			font-size: 3rem;
			margin-left: 5rem;
			letter-spacing: 6px;
			padding-left: 1rem;
			border-left: 1px solid;
		}
		.stxt {
			margin-left: 0;
		}
	}
	.vertical-box {
		text-align: center;
		margin: 6rem auto;
	}
}


@include mq {
	.modaal-container {
		width: 100% !important;
	}
	.modal-content {
		.cnt {
			margin: 0;
			.title {
				font-size: 3rem;
				margin-top: -2rem;
				line-height: 1.4;
			}
		}
		.vertical-box {
			margin: 2rem auto;
		}
		.vertical {
			writing-mode: initial;
			p {
				margin: 1rem auto;
				font-size: 1rem;
			}
			.title {
				font-size: 3rem;
				margin: 0rem auto 2rem;
				letter-spacing: 6px;
				padding-left: 0;
				border-left: none;
			}
			.stxt {
				margin: 0;
			}
		}
	}
}
