// .body {
// 	overflow: hidden;
// 	transition: all 1s 1.2s;
// 	opacity: 0;
// 	transform: translateY(5rem);
// 	&.on {
// 		opacity: 1;
// 		transform: translateY(0);
// 	}
// }

.relative {
	position: relative;
}
.inline {
	display: inline;
}
.ib {
	display: inline-block;
}

// プラグイン調整
.fbc-wrap {
	display: flex;
	justify-content: center;
}
.modaal-noscroll {
	overflow: auto !important;
}



.dn {
	display: none;
}

// color
.pink {
	color: $pink;
}

// bg color
.bg-red {
	background-color: $red;
	color: #fff;
}
.bg-green {
	background-color: $green;
	color: #fff;
}

.theme-hr {
	width: 100%;
	height: 2px;
	background: #EFEFEF;
	position: relative;
	z-index: 0;
	margin: 4rem auto;
	&::after {
		content: "";
		width: 16px;
		height: 16px;
		background: #000;
		border: 4px solid #fff;
		border-radius: 50%;
		@extend .centering;
	}
}

.table {
	border-collapse: collapse;
	th,td {
		padding: 1rem;
		border: 1px solid white;
	}
	th {
		width: 30%;
	}
}

.mb-visible {
	display: none;
}

.img-wrap {
	margin: 2rem auto;
}
.text-box {
	margin: 4rem auto;
}

.comp-thumbbox {
	position: relative;
	z-index: 0;
	overflow: hidden;
	&::before,&::after {
		transition: all .6s;
		pointer-events: none;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 0;
		z-index: 2;
		background-color: rgba(0, 0, 0, 0.75);
	}
	&::after {
		content: "MORE →";
		@extend .centering;
		z-index: 3;
		top: 70%;
		opacity: 0;
		padding: .5rem;
		border: 1px solid;
		letter-spacing: 1px;
		line-height: 1;
	}
	&:hover {
		&::before {
			height: 100%;
		}
		&::after {
			opacity: 1;
			top: 50%;
		}
	}
}


.figure {
	img {
		width: 100%;
		margin: 1rem auto;
	}
	figcaption {}
}

@include mq(max) {
}

@include mq(slaptop) {
}

@include mq(laptop) {
}

@include mq(wtab) {

}
@include mq {
	.mb-block {
		display: block;
	}
	.mb-visible {
		display: block;
	}
	.mb-hidden {
		display: none;
	}
}
@include mq(ssp) {
	.theme-hr {
		margin: 4rem auto;
	}
}
