.tab {
	display: flex;
	margin: 0 auto !important;
	.item {
		cursor: pointer;
		padding: .5rem 1rem;
		&:hover {

		}
		&.select {
			background-color: $gray;
		}
		.title {
			margin: 0;
			font-size: .9rem;
		}
	}
}
.tab-box {
	background-color: $gray;
	padding: 2rem;
	.item {
		display: none;
		&.select {
			display: block;
		}
		.title {
			color: $black;
			font-weight: normal;
			margin-bottom: 1.5rem;
		}
		.text {
			margin: 0;
		}
		.content {
			margin-bottom: 2.5rem;
		}
	}
}

.tab-cnt-wrapper {
	margin: 3rem auto;
}

.tab-wrap {
	overflow: auto;
}

@include mq {
	.tab {
		width: 41rem;
	}
}
