// plugins
// tablepress
.tablepress {
	td {
		padding: 1rem !important;
		border: 1px solid #fff !important;
	}
	iframe {
		margin-top: 1rem;
	}
	img {
		max-width: 100% !important;
	}
	.column-1 {
		width: 20%;
		text-align: center;
	}
}


@include mq {
	.tablepress {
		.column-1 {
			width: 100%;
			text-align: center;
			display: block;
			background-color: #ffffff;
			color: black;
		}
		.column-2 {
			display: block;
			width: 100%;
		}
	}
}
