// decoration
.underline {
	text-decoration: underline;
}

.mincho {
	font-family: $mincho;
}

// サイズ
.small {
	font-size: .75rem;
}
.medium {
	font-size: 1.5rem;
}
.large {
	font-size: 2rem;
}

// 
.bold {
	font-weight: bold;
}

// color
.green {
	color: $green;
}
.red {
	color: $red;
}
.attention {
	color: #F92828;
}

// align
.center {
	text-align: center;
}
.right {
	text-align: right;
}
.vertical {
	writing-mode: vertical-rl;
	white-space: nowrap;
	line-break: strict;
	text-align: justify;
}

// text transform
.txt-tranform-capitalize {
	text-transform: capitalize;
}

// weight
.bold {
	font-weight: bold;
}

// link
.link {
	&:hover {
		color: $blue;
	}
}
.link-inline {
	color: $blue;
	text-decoration: underline;
	display: inline;
	margin-right: .25rem;
	margin-left: .25rem;
}
.link2 {
	&:hover {
		text-decoration: underline;
	}
}


@include mq(slaptop) {
}

@include mq(wtab) {
	.small {
		font-size: 1vw;
	}
}
