.btn-tel {
	background-color: red;
	display: inline-block;
	padding: .74em 1em;
	line-height: 1;
	border-radius: 4px;
	font-size: 2vw;
}
.btn {
	&:hover {
		transform: scale(1.1);
	}
}
// .btn2 {
// 	@extend .btn;
// 	background-color: white;
// 	padding: 1rem 2rem;
// 	border: none;
// 	border-radius: 12px;
// 	color: $black;
// 	&:hover {
// 		color: $blue;
// 	}
// }
// .btn3 {
// 	border: 1px solid #fff;
// 	line-height: 1;
// 	padding: 1rem;
// 	border-radius: 4px;
// 	display: block;
// 	text-align: center;
// 	position: relative;
// 	overflow: hidden;
// 	&::after {
// 		content: "";
// 		transition: all .4s;
// 		position: absolute;
// 		top: 0;
// 		left: -100%;
// 		width: 100%;
// 		height: 100%;
// 		background: #fff;
// 		z-index: -1;
// 	}
// 	&:hover {
// 		color: #000;
// 		&::after {
// 			left: 0;
// 		}
// 	}
// }

// .btn-wrap {
// 	margin: 2rem auto;
// }

// .btn-entry {
// 	display: inline-block;
// 	line-height: 1;
// 	border: none;
// 	background-color: $red;
// 	font-size: 1.5vw;
// 	padding: 1.5rem 2em;
// 	border-radius: 56px;
// 	position: relative;
// 	z-index: 1;
// 	overflow: hidden;
// 	span {
// 		position: relative;
// 		z-index: 3;
// 	}
// 	&::after {
// 		content: "";
// 		transition: all .4s;
// 		position: absolute;
// 		left: 0;
// 		top: -100%;
// 		width: 100%;
// 		height: 100%;
// 		background: rgb(255,0,0);
// 		background: linear-gradient(45deg, rgba(255,0,0,1) 0%, rgba(122,31,255,1) 44%, rgba(255,0,0,1) 100%);
// 		z-index: 2;
// 		opacity: 0;
// 	}
// 	&:hover {
// 		&::after {
// 			top: 0;
// 			opacity: 1;
// 		}
// 	}
// }

// .menu-btn {
// 	width: 3rem;
// 	height: 2rem;
// 	position: relative;
// 	z-index: 100000;
// 	overflow: hidden;
// 	cursor: pointer;
// 	.line {
// 		background-color: white;
// 		width: 100%;
// 		position: absolute;
// 		height: 2px;
// 		border-radius: 64px;
// 		transition: all .4s;
// 	}
// 	.line1 {
// 		top: 0;
// 		left: 0;
// 	}
// 	.line2 {
// 		@extend .centeringY;
// 		left: 0;
// 	}
// 	.line3 {
// 		bottom: 0;
// 		left: 0;
// 	}
// 	&.open {
// 		.line1 {
// 			top: 17px;
// 			left: -5px;
// 			width: calc(100% - 9px);
// 			transform: rotate(45deg);
// 		}
// 		.line2 {
// 			left: -3rem;
// 		}
// 		.line3 {
// 			bottom: 13px;
// 			left: -5px;
// 			transform: rotate(-45deg);
// 			width: calc(100% - 9px);
// 		}
// 	}
// }

// @include mq {
// 	.btn-entry {
// 		font-size: 1rem;
// 	}
// 	.menu-btn.open {
// 		.line {
// 		}
// 		.line1 {
// 			top: 14px;
// 			left: 0px;
// 		}
// 		.line3 {
// 			bottom: 16px;
// 			left: 0px;
// 		}
// 	}
// }
