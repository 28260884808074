.top-title {
	font-size: 3vw;
	font-weight: normal;
	line-height: 1.4;
	margin-bottom: 3rem;
	text-align: center;
}

.about-cnt {
	& > .item-left {
		background-image: url(../images/about.jpg);
		background-size: cover;
		background-position: center;
		margin-right: 3rem;
	}
	& > .item-right {
		.title {
			font-size: 4.4vw;
			font-weight: normal;
			line-height: 1.4;
			margin-bottom: 0rem;
		}
		.text {
			margin: 2rem auto;
			line-height: 1.6vw;
		}
	}
}

.about-sozai-cnt {
	text-align: center;
	.text {
		margin: 2rem auto;
		line-height: 1.6vw;
	}
}

.sozai-detail {
	img {
		width: 100%;
	}
	&.last {
		justify-content: space-between;
		flex-basis: 48%;
	}
}

.menu-list {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
	dd {
		flex-shrink: 0;
		margin-left: 1.5rem;
	}
}
.menu-item {

}

#menu {
	background-color: rgba(255, 255, 255, 0.73);
	padding-bottom: 1rem;
}
.menu-title {
	background-image: url(../images/menu.jpg);
	background-size: cover;
	background-position: center;
	padding: 14rem 0;
	position: relative;
	span {
		@extend .centering;
		display: block;
		color: #fff;
		z-index: 1;
		font-size: 5.5vw;
	}
	&::after {
		content: "";
		@extend .centering;
		z-index: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .25);
	}
}
.menu-wrap {
	margin: 6rem auto;
	.title {
		font-size: 3rem;
		text-align: center;
		font-weight: normal;
		margin-bottom: 2rem;
		span {
			border-bottom: 1px solid;
			padding-bottom: .5rem;
		}
	}
}
.subtitle {
	font-family: serif;
	font-size: 1.75rem;
	font-weight: normal;
	margin-bottom: 1rem;
}
.enkai {
	ul {
		margin-bottom: 2rem;
	}
	.title {
		font-size: 2rem;
	}
}
.enkai-text {
	text-align: center;
	margin-bottom: 3rem;
}
.enkai-img {
	margin: 4rem auto;
}





@include mq(max) {
	.about-cnt & > .item-right {
		.title {
			font-size: 5.5rem;
		}
		.text {
			line-height: 1.6;
		}
	}
	.top-title {
		font-size: 4rem;
	}
	.about-sozai-cnt .text {
		line-height: 1.6;
	}
}

@include mq(laptop) {
	.about-txt {
		p {
			font-size: 1.1rem;
		}
		.title {
			font-size: 2rem;
		}
	}
}

@include mq(tab) {
	.about-cnt {
		& > .item-left {
		}
		& > .item-right {
			.title {
				font-size: 6.4vw;
			}
			.text {
				line-height: 1.5;
			}
		}
	}
	.top-title {
		font-size: 5vw;
	}
	.about-sozai-cnt .text {
		line-height: 1.5;
	}
	.menu-title {
		padding: 23vw 0;
		span {
			font-size: 11.5vw;
		}
	}
	.enkai {
		display: block;
		text-align: center;
		.bg {
			margin-bottom: 4rem;
		}
	}
	.reserve a {
		font-size: 7vw;
	}
}
 
@include mq() {
	.about-cnt {
		& > .item-right {
			.title {
				font-size: 14.4vw;
				font-weight: normal;
				line-height: 1.3;
				margin: 2rem auto;
			}
			.text {
				line-height: 1.6;
			}
		}
		& > .item-left {
			height: 13rem;
			margin: 0;
		}
	}
	.top-title {
		font-size: 6.7vw;
	}
	.about-sozai-cnt {
		text-align: left;
		.text {
			line-height: 1.6;
		}
	}
	.sozai-wrap {
		display: block;
	}
	.menu-title {
		padding: 7rem 0;
		span {
			font-size: 16.5vw;
			width: 90%;
		}
	}
	.menu-wrap {
		margin: 0rem auto;
	}
	.menu-wrap-inner {
		display: block;
		.item {
			margin: 0 0 2rem;
		}
	}
	.enkai-img {
		margin: 2rem auto;
	}
	.enkai .title {
		margin: 2rem auto;
	}
}
