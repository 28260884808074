.fade-in {
	opacity: 0;
	position: relative;
	z-index: 1;
	transform: translateY(2rem);
	transition: all 1s;
	&.on {
		opacity: 1;
		transform: translateY(0);
	}
}
.fade-in-right {
	@extend .fade-in;
	transform: translateX(2rem);
	&.on {
		transform: translateX(0);
	}
}

.text-fade-in {
	@extend .fade-in;
	display: inline-block;
	letter-spacing: 14px;
	&.on {
		letter-spacing: 2px;
	}
}


.slide-in {
	opacity: 0;
	position: relative;
	transform: translateX(-2rem);
	transition: all 1s;
	&.on {
		opacity: 1;
		transform: translateX(0);
	}
}
.slide-in-wrapper {
	.item {
		@for $index from 1 through 100 {
			&:nth-child(#{$index}) {
				.text-slide-in {
					transition-delay: #{$index*0.2}s;
				}
			}
		}
	}
}
.text-slide-in {
	@extend .slide-in;
}



@include mq {
	.text-fade-in {
		letter-spacing: 0;
		display: block;
		&.on {
			letter-spacing: 0;
		}
	}
}
