//color
$green: #4FC994;

$blue: #4ac4ff;

$red: #FF223C;

$orange: #F4513B;

$gray: #F0F0F0;
$gray-d: #799AA7;

$black: #464646;
$black-bg: #0F0600;

$yellow: #FFCF48;

$purple: #9076FF;

$pink: #FF99CC;

$text: #456571;
$text-green: #08C171;

$facebook: #3B5998;
$twiiter: #55acee;
$instagram: #b900b4;

// //font
$basefont: futura-pt,ryo-gothic-plusn, "Yu Gothic", YuGothic,-apple-system, BlinkMacSystemFont, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro",メイリオ, Meiryo ,Helvetica, sans-serif;
$mincho: ryo-text-plusn, "Yu Mincho", "YuMincho", "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
